﻿import Vue from 'vue'
import App from './App'
import router from './router'
import vuetify from '@/plugins/vuetify'
import VueKeepScrollPosition from 'vue-keep-scroll-position'
import '@/Content/css/Site.css';
import moment from 'moment'
import './registerServiceWorker';
import StackTrace from 'stacktrace-js';
//import '@/Content/css/SwaggerHeader.css';


Vue.use(VueKeepScrollPosition);

Vue.config.productionTip = false
//Global Vars
Vue.prototype.$MyLRCUrl = Vue.observable({ value: "https://mylrc.sdlegislature.gov/" });
Vue.prototype.$BasePath = Vue.observable({ value: location.protocol + '//' + location.host + '/' });
Vue.prototype.$LoggedIn = Vue.observable(false);
Vue.prototype.$menuDocuments = Vue.observable({ value: [] });
Vue.prototype.$sideMenuDocuments = Vue.observable({ value: [] });
Vue.prototype.$MenuItems = Vue.observable({ value: [] });
Vue.prototype.$Environment = Vue.observable({ value: 'Production' });

/* eslint-disable no-new */
const app = new Vue({
    el: '#app',
    vuetify,
    router,
    components: { App },
    template: '<App/>',
    render: h => h(App),
});

router.beforeResolve(function (to, from, next) {
    if (to.fullPath && to.fullPath.includes('\/#\/')) {
        let test = to.fullPath.replace('\/#\/', '\/');
        next(test);
    }
    else {
        next();
    }
});
router.afterEach(function (to, from) {

});
router.beforeEach(function (to, from, next) {
    if (true) {
        if (to.fullPath && to.fullPath.includes('\/#\/')) {
            let test = to.fullPath.replace('\/#\/', '\/');
            next(test);
        }
        else {
            next();
        }
    } else {
        next(false);
    }
});

window.MemberTypeFilter = function (value) {
    return MemberType(value);
}

Vue.filter('MemberTypeFilter', MemberTypeFilter);

window.FormatDates = function (value) {
    if (!isValidDate(value))
        return value;
    //if (value.getHours() == 0)
    return value.toLocaleDateString();
    //return value.toLocaleString();
}
Vue.filter('FormatDates', FormatDates);

window.FormatDateTimes = function (value) {
    if (!isValidDate(value))
        return value;

    let TimeZone = new Date().toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2] || '';

    return moment(value).format('MM/DD/YYYY h:mm A') + ' ' + TimeZone;
}

Vue.filter('FormatDateTimes', FormatDateTimes);

window.FormatTimes = function (value) {
    if (!isValidDate(value))
        return value;
    let TimeZone = new Date().toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2] || '';
    if (value.getHours() == 0)
        return moment(value).format('MM-DD-YYYY');
    return moment(value).format('h:mm A') + ' ' + TimeZone;
}

Vue.filter('FormatTimes', FormatTimes);

window.FormatDatesLongDay = function (value) {
    if (!isValidDate(value))
        return value;
    if (value.getHours() == 0)
        return moment(value).format('dddd, MMMM DD, YYYY');
    return moment(value).format('dddd, MMMM DD, YYYY h:mm A');
}
Vue.filter('FormatDatesLongDay', FormatDatesLongDay);

var mainjsError = null;
var mainjsErrorSource = null;

Vue.config.errorHandler = function (err, vm, info) {
    mainjsError = err;
    mainjsErrorSource = null;
    StackTrace.fromError(err).then(callback).catch(errback);
}
window.onerror = function (msg, src, linenum, colnum, error) {
    mainjsError = error;
    mainjsErrorSource = src;
    if (error) {
        StackTrace.fromError(error).then(callback).catch(errback);
    }
    else {
        StackTrace.get().then(callback).catch(errback);
    }
};
var errback = function (err) {
    console.error(err);
};
var callback = function (stackframes) {
    var stringifiedStack = stackframes.map(function (sf) {
        return sf.toString();
    }).join('\n');
    try {
        let url = null;
        if (window && window.location) {
            url = window.location.href;
        }
        fetch("/api/Errors", {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                Message: mainjsError.message || "",
                Source: mainjsErrorSource, //JSON.stringify({ vm }, getCircularReplacer()),
                StackTrace: stringifiedStack,
                Path: url,
                DateOccured: new Date()
            }),
        });
        console.error(mainjsError);
    }
    catch (ex) {
        console.error(ex);
    }
};
if (window.location.host.toLowerCase().startsWith('www.')) {
    window.location.replace(window.location.href.toLowerCase().replace('www.', ''));
    console.log('Redirected from www. to root');
}