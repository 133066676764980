//import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
import Vue from 'vue'
import Vuetify from 'vuetify/lib'
//import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify)

const mq = false;//window.matchMedia('(prefers-color-scheme: dark)')

const opts = {
    theme: { dark: mq.matches },
    themes: {
        light: {},
        dark: {}
    },
    icons: {
        iconfont: 'mdiSvg',
    },
}

export default new Vuetify(opts)